import * as Sentry from '@sentry/react';

declare global {
  interface Window {
    Sentry: {
      envelopes: any[];
    };
  }
}

const isProduction = PRODUCTION === 'true';
const isDevelopment = DEVELOPMENT === 'true';

const isPreview = !!RELEASE_INFO.match(/-preview#\d+/);

const config: Sentry.BrowserOptions = {
  enabled: true,
  dsn: COMPANY_SENTRY_DSN,
  release: RELEASE_INFO,
  environment: ENV,
  sampleRate: isProduction ? 0.25 : 1,
  ignoreErrors: [
    // Following error is caused by weird chrome extension, ignore
    'Extension context invalidated.',

    // Following error is caused by ad blocker, there's nothing we can do
    // in this case
    'Resource blocked by content blocker',

    // Error codes we can safely ignore; if there is a problem, it's probably not
    // on our side
    /Request failed with status code 401/,
    /Request failed with status code 403/,
    /Request failed with status code 404/,
    /Request failed with status code 409/,

    // The request was dropped, the user is offline, etc.
    /Network ?Error/,
    /Request aborted/,
  ],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (hint && breadcrumb.data && breadcrumb.category === 'xhr') {
      try {
        const response = JSON.parse(hint.xhr.response);
        const requestId = response.meta?.request_id ?? response.meta?.request?.request_id;

        if (requestId) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.requestId = requestId;
        }

        if (breadcrumb.data.status_code >= 400) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.errors = response.errors;
        }
      } catch {
        // do nothing
      }
    }

    return breadcrumb;
  },
};

// Dev-only logging so we can verify what our handlers are doing without actually
// sending anything to Sentry
if (isDevelopment || isPreview) {
  window.Sentry = {
    envelopes: [],
  };
  // Must match their DSN validation:
  // https://github.com/getsentry/sentry-javascript/blob/bfe819d6737dafc86c60419f644c55a4630bb626/packages/utils/src/dsn.ts#L35
  config.dsn = 'https://dummy@dummy/123456';
  config.transport = () => ({
    send: ([envelopeHeaders, envelopeItems]) => {
      window.Sentry.envelopes.push({ headers: envelopeHeaders, items: envelopeItems });
      // we don't care about response payload type here
      return Promise.resolve({} as any);
    },
    flush: () => Promise.resolve(true),
  });
}

Sentry.init(config);
