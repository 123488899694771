import { createGlobalStyle } from 'styled-components';

import { SIDE_NAV_PROPERTY, SIDE_NAV_WIDTH } from 'common/hooks/useSideNavExpanded';

export default createGlobalStyle`
body {
  color-scheme: light;
  ${SIDE_NAV_PROPERTY}: ${SIDE_NAV_WIDTH.Expanded};
}
`;
